<template>
  <div ref="progress-bar" id="progress-bar">
    <div v-for="step in steps" :key="step.id" :ref="step.id" class="step">
      <div class="icon-container mr-2">
        <div :class="'icon ' + progressBarClass(step)">
          <b-icon :icon="stepIcon(step)" :font-scale=" stepIcon(step) === 'check' ? '1.2' : '0.6'" />
        </div>
      </div>
      <div class="step-progress-and-name mr-2">
        <div :class="'step-total ' + progressBarClass(step)" />
        <div v-if="progressBarClass(step) === 'current'" class="step-progress visited" :style="`width: ${slide.progress * 100}%;`" />
        <div :class="'step-name ' + stepClass(step)">
          {{ step.title }}
        </div>
      </div>
    </div>

    <div class="icon-container">
      <div class="icon incomplete-stage">
        <b-icon icon="trophy-fill" font-scale="0.75" />
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ProgressBar',
  computed: {
    ...mapGetters('stagelineV3', [
      'steps',
      'slide',
    ]),
  },
  watch: {
    slide(current, prev) {
      if (current.step?.id !== prev.step?.id) {
        const element = this.$refs[current.step.id][0]
        const parent = element.parentNode

        const screenBasedOffset = window.innerWidth * 0.15
        const offset = element.offsetLeft - (parent.clientWidth / 2) + (element.clientWidth / 2) - screenBasedOffset

        parent.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      }
    },
  },
  methods: {
    currentStepIndex() {
      if (!this.slide.step) return 0
      return this.stepIndex(this.slide.step)
    },
    stepIndex(step) {
      return this.steps.indexOf(this.steps.find(s => s.id === step?.id))
    },
    progressBarClass(step) {
      if (this.currentStepIndex() < this.stepIndex(step)) {
        return 'unvisited'
      } else if (this.currentStepIndex() > this.stepIndex(step)) {
        return 'visited'
      } else {
        return 'current'
      }
    },
    stepClass(step) {
      return this.currentStepIndex() > this.stepIndex(step) ? 'visited' : 'unvisited'
    },
    stepIcon(step) {
      if (this.currentStepIndex() < this.stepIndex(step)) {
        return 'circle-fill'
      } else if (this.currentStepIndex() > this.stepIndex(step)) {
        return 'check'
      } else {
        return 'pencil-fill'
      }
    },
  },
}
</script>

<style scoped lang="scss">
#progress-bar {
  ::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  .step {
    flex: 1 1 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 10em;

    .step-progress-and-name {
      margin-top: 0.5em;
      display: flex;
      flex-direction: column;
      width: 100%;

      .step-total, .step-progress  {
        height: 0.625em;
        border-radius: 1em;
      }

      .step-progress {
        position: relative;
        top: -9.5px;
      }

      .step-name {
        font-size: 0.875em;
        margin-top: 0.5em;
        overflow: hidden;
        background-color: white !important;
      }

      .current {
        background-color: #B2E2EC;
      }

      .visited {
        background-color: #009FBF;
        color: #009FBF;
      }

      .unvisited {
        background-color: #BABDC0;
        color: #BABDC0;
      }
    }
  }

  .icon-container {
    display: flex;
    align-items: flex-start;

    .icon {
      display: flex;
      cursor: auto;
      margin: 0;
      justify-content: center;
      align-items: center;
      height: 1.25em;
      width: 1.25em;
      border-radius: 50%;
      color: lightgray;
    }

    .current {
      border: 0.2em solid #009FBF;
      border-radius: 50%;
      padding: 0.5em;
      color: #009FBF;
    }

    .visited {
      background-color: #009FBF;
      color: white;
    }

    .unvisited {
      background-color: #BABDC0;
      color: white;
      height: 0.9em !important;
      width: 0.9em !important;
      margin-top: 0.25em;
    }

    .incomplete-stage {
      background-color: #BABDC0;
      color: white;
    }

    .complete-stage {
      background-color: #009FBF;
      color: white;
    }
  }
}
</style>
