var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "progress-bar", attrs: { id: "progress-bar" } },
    [
      _vm._l(_vm.steps, function (step) {
        return _c(
          "div",
          { key: step.id, ref: step.id, refInFor: true, staticClass: "step" },
          [
            _c("div", { staticClass: "icon-container mr-2" }, [
              _c(
                "div",
                { class: "icon " + _vm.progressBarClass(step) },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.stepIcon(step),
                      "font-scale":
                        _vm.stepIcon(step) === "check" ? "1.2" : "0.6",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "step-progress-and-name mr-2" }, [
              _c("div", { class: "step-total " + _vm.progressBarClass(step) }),
              _vm.progressBarClass(step) === "current"
                ? _c("div", {
                    staticClass: "step-progress visited",
                    style: `width: ${_vm.slide.progress * 100}%;`,
                  })
                : _vm._e(),
              _c("div", { class: "step-name " + _vm.stepClass(step) }, [
                _vm._v("\n        " + _vm._s(step.title) + "\n      "),
              ]),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "icon-container" }, [
        _c(
          "div",
          { staticClass: "icon incomplete-stage" },
          [
            _c("b-icon", {
              attrs: { icon: "trophy-fill", "font-scale": "0.75" },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }